import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Pagination, Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductCard from './ProductCard';

function Shop() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 4;
  const categories = ["paintings", "t-shirts", "bags", "cups", "custom Signs"];

  useEffect(() => {
    fetch('/products.json') 
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="bg-light py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1 className="display-4 font-weight-bold mb-4">Shop Purple Lotus Design</h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        <Tab.Container defaultActiveKey="paintings">
          <Nav variant="tabs" className="custom-tab-nav">
            {categories.map((category) => (
              <Nav.Item key={category}>
                <Nav.Link eventKey={category}>{category.charAt(0).toUpperCase() + category.slice(1)}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          
          <Tab.Content className="mt-4">
            {categories.map((category) => (
              <Tab.Pane eventKey={category} key={category}>
                <Row>
                  {products
                    .filter((product) => product.category === category)
                    .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
                    .map((product) => (
                      <Col key={product.id} md={3}>
                        <Link to={`/product/${product.id}`} className="product-card-link">
                          <ProductCard name={product.name} price={product.price} image={product.image} />
                        </Link>
                      </Col>
                    ))}
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Pagination className="justify-content-center">
                      {[...Array(Math.ceil(products.filter(p => p.category === category).length / productsPerPage)).keys()].map((page) => (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currentPage}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </Col>
                </Row>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Shop;
