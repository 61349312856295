import React from 'react';
import { Card } from 'react-bootstrap';

function ProductCard({ name, price, image }) {
  return (
    <Card className="mb-4 shadow-sm hover-zoom">
      <Card.Img variant="top" src={image} alt={name} className="product-image" />
      <Card.Body className="text-center">
        <Card.Title className="product-card-title">{name}</Card.Title>
        <Card.Text className="text-muted product-card-text">
          Price: ${price.toFixed(2)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;
