import React from 'react';
import { Container } from 'react-bootstrap';

function PrivacyPolicy() {
  return (
    <Container className="mt-5">
      <h2>Privacy Policy</h2>
      <p>Effective Date: Nov. 14th, 2024</p>
      
      <section className="mt-4">
        <h5>1. Introduction</h5>
        <p>Thank you for visiting The Purple Lotus Design website. This privacy policy outlines how we handle any personal information you may provide while visiting or interacting with this site.</p>
      </section>
      
      <section className="mt-4">
        <h5>2. Information We Collect</h5>
        <p>We may collect the following information when you submit an order:</p>
        <ul>
          <li>Your name</li>
          <li>Email address</li>
          <li>Shipping address</li>
          <li>Order details (product, size, custom message)</li>
        </ul>
      </section>
      
      <section className="mt-4">
        <h5>3. How We Use Your Information</h5>
        <p>We use the information you provide solely to process your order and respond to any inquiries. Your information will not be shared with third parties without your consent, except as necessary to complete your transaction.</p>
      </section>

      <section className="mt-4">
        <h5>4. Security of Your Information</h5>
        <p>We take appropriate measures to protect the security of your information during submission and storage. However, please note that no method of transmission over the internet is 100% secure.</p>
      </section>
      
      <section className="mt-4">
        <h5>5. Your Rights</h5>
        <p>You have the right to request access to, correction of, or deletion of your personal information at any time. Please contact us at (thepurplelotusdesign@gmail.com) with any requests or questions.</p>
      </section>
      
      <section className="mt-4">
        <h5>6. Updates to This Policy</h5>
        <p>We may update this privacy policy from time to time. Any changes will be posted on this page, so please review it periodically.</p>
      </section>
      
      <section className="mt-4">
        <h5>7. Contact Us</h5>
        <p>If you have any questions or concerns regarding this privacy policy, please reach out to us at (thepurplelotusdesign@gmail.com).</p>
      </section>
    </Container>
  );
}

export default PrivacyPolicy;
