import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram } from 'react-icons/fa';
import LegendDairyLogo from '../LDLogoBang_NoWhite.png';
import '../App.css';

function Footer() {
  return (
    <footer style={{ backgroundColor: '#2E073F', color: '#EBD3F8', padding: '20px 0' }} className="footer">
      <Container>
        <Row className="align-items-center">

          <Col md={6} className="text-center text-md-start mb-3 mb-md-0">
            <h5>Follow Us</h5>
            <div className="d-flex justify-content-center justify-content-md-start">
              <a href="https://www.instagram.com/purplelotusdesign/" target="_blank" rel="noopener noreferrer" className="mx-2" style={{ color: '#EBD3F8' }}>
                <FaInstagram size={24} />
              </a>
            </div>
          </Col>

          <Col md={6} className="text-center text-md-end">
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <span style={{ marginRight: '10px' }}>Website created by</span>
              <img src={LegendDairyLogo} alt="LegendDairy Productions Logo" style={{ height: '30px' }} />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
