import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from '../PLD_Logo.png';
import '../App.css';

function About() {
  return (
    <>
    <div className="bg-light py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h1 className="display-4 font-weight-bold mb-4">Discover the story behind Purple Lotus Design</h1>
          </Col>
        </Row>
      </Container>
    </div>

    <Container className="mt-5">
      <Row className="mt-4 d-flex justify-content-center align-items-center">
        <Col md={4} className="d-flex justify-content-center image-background">
          <Image src={logo} rounded fluid alt="Brand and Products" />
        </Col>
        <Col md={8}>
          <h2 className="section-heading">About the Brand</h2>
          <p className="text-center text-md-start">At Purple Lotus Design, we believe in the power of creativity to uplift and inspire. Our custom inspirational apparel and accessories are designed to empower you, featuring unique messages that resonate with your journey. Each piece is crafted with care, making it a perfect addition to your wardrobe or a thoughtful gift for someone special.</p>
          <p className="text-center text-md-start">Each of our pour paintings are uniquely crafted to inspire the fruits of the spirit: love,  joy, peace, kindness, goodness and faithfulness. Hang one in your space and let the soothing colors transform your home into a sanctuary.</p>
          <p className="text-center text-md-start">Discover the beauty of self-expression and serenity with Purple Lotus Design, where every item tells a story and inspires a journey toward a more peaceful life.</p>
          <p className="text-center text-md-start"><i>Galatians 5:22-26</i></p>
        </Col>
      </Row>

      <Row className="mt-4 d-flex justify-content-center align-items-center">
        <Col md={8}>
          <h2 className="section-heading">About the Owner</h2>
          <p className="text-center text-md-end">Hello! I’m Sarai Gholson, the artist and founder of The Purple Lotus Design based in beautiful Colorado. My journey in creativity has led me through countless artistic pursuits, but it was a pivotal global moment that truly set my business into motion.</p>
          <p className="text-center text-md-end">Like many, I faced the challenges of 2020 by searching for an outlet to bring calm and peace of mind. When I discovered acrylic pouring, a mesmerizing painting technique that creates unique, flowing designs, I was captivated. This art form reconnected me with my love for creating, sparking a renewed passion and inspiring the foundation of The Purple Lotus Design.</p>
          <p className="text-center text-md-end">From customizable signs and hand-painted cups to vibrant merchandise and one-of-a-kind artwork, my work is all about bringing joy and warmth into people’s lives. What started as a creative escape has transformed into a platform that allows me to share my love for art and craft meaningful pieces for others.</p>
          <p className="text-center text-md-end">I look forward to helping make your next event memorable, adding warmth to your home, or creating that perfect piece to brighten your day. Thank you for being a part of this journey with me!</p>
        </Col>
        <Col md={4} className="d-flex justify-content-center">
          <Image src="\images\SaraiGholson2.jpeg" roundedCircle fluid alt="Owner of Purple Lotus Design" />
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default About;
