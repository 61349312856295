import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ProductForm() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetch('/products.json')
      .then((response) => response.json())
      .then((data) => {
        const selectedProduct = data.find((item) => item.id === productId);
        setProduct(selectedProduct);
      });
  }, [productId]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      productName: product?.name || '',
      productPrice: product?.price || 0,
      productSize: selectedSize,
      customerName: e.target.customerName?.value || '',
      customerEmail: e.target.customerEmail?.value || '',
      customerAddress: e.target.customerAddress?.value || '',
      customerMessage: e.target.customerMessage?.value || '',
    };

    try {
      const response = await fetch('https://the-purple-lotus-design-website-backend.onrender.com/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Order submitted successfully!');
        navigate('/order-confirmation', { state: formData });
      } else {
        alert('Failed to submit order.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your order.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return product ? (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Order Form</h2>
      <Row className="align-items-center">
        <Col md={6}>
          <Card className="p-4 shadow-sm border-0">
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="productName">
                  <Form.Label>Product</Form.Label>
                  <Form.Control type="text" readOnly value={product.name} className="form-control-lg border-0 bg-light" />
                </Form.Group>

                <Form.Group controlId="productPrice" className="mt-3">
                  <Form.Label>Price <i>(plus shipping)</i></Form.Label>
                  <Form.Control type="text" readOnly value={`$${product.price.toFixed(2)}`} className="form-control-lg border-0 bg-light" />
                </Form.Group>

                {product.category === 't-shirts' && (
                  <Form.Group controlId="productSize" className="mt-3">
                    <Form.Label>Size</Form.Label>
                    <Form.Select
                      required
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                      className="form-control-lg bg-light"
                    >
                      <option value="">Select Size</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                      <option value="X-Large">X-Large</option>
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group controlId="customerName" className="mt-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" required className="form-control-lg bg-light" />
                </Form.Group>

                <Form.Group controlId="customerEmail" className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" required className="form-control-lg bg-light" />
                </Form.Group>

                <Form.Group controlId="customerAddress" className="mt-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter shipping address" required className="form-control-lg bg-light" />
                </Form.Group>

                {product.category !== 'paintings' && (
                  <Form.Group controlId="customerMessage" className="mt-3">
                    <Form.Label>Custom Text</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Add custom text" className="bg-light" />
                  </Form.Group>
                )}

                <Button
                  className="submit-button mt-4 w-100 btn-lg"
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: isSubmitting ? '#CCCCCC' : '#2E073F',
                    borderColor: '#2E073F',
                    color: 'white',
                  }}
                  onMouseEnter={(e) => {
                    if (!isSubmitting) e.target.style.backgroundColor = '#EBD3F8';
                  }}
                  onMouseLeave={(e) => {
                    if (!isSubmitting) e.target.style.backgroundColor = '#2E073F';
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Order'}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex justify-content-center">
          <Card className="shadow-sm border-0" style={{ maxWidth: '100%' }}>
            <Card.Img
              variant="top"
              src={product.image}
              alt={product.name}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="mt-5 text-center">
      <p>Loading product details...</p>
    </Container>
  );
}

export default ProductForm;
