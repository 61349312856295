import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../PLD_Logo.png';

function CustomNavbar() {
  return (
    <Navbar style={{ backgroundColor: '#2E073F' }} variant="dark" expand="lg" className="custom-navbar shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/" className="font-weight-bold">
          <img
            src={logo}
            alt="Business Logo"
            width="50"
            height="50"
            className="d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" className="px-3">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="px-3">About</Nav.Link>
            <Nav.Link as={Link} to="/shop" className="px-3">Shop</Nav.Link>
            <Nav.Link as={Link} to="/privacy-policy" className="px-3">Privacy Policy</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
