import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

function Home() {
  return (
    <>
      <div className="bg-light py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <h1 className="display-4 font-weight-bold mb-4">Welcome to Purple Lotus Design</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="carousel-container">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <Carousel interval={2500} fade>
              <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image6.jpg"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image7.jpg"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image1.JPEG"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image2.JPEG"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image3.JPEG"
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image4.JPEG"
                    alt="Fourth slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/images/C-Image5.JPEG"
                    alt="Fifth slide"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;
