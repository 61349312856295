import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';

function OrderConfirmation() {
  const location = useLocation();
  const orderDetails = location.state;

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          {orderDetails ? (
            <Card className="p-4 shadow border-0">
              <Card.Body>
                <h2 className="section-heading">Thank you for your order, {orderDetails.customerName}!</h2>
                <p className="text-center text-muted">Your order has been successfully received. Here are your order details:</p>
                
                <ul className="list-unstyled my-4">
                  <li><strong>Product:</strong> {orderDetails.productName}</li>
                  <li><strong>Price:</strong> ${orderDetails.productPrice.toFixed(2)}</li>
                  {orderDetails.productSize && <li><strong>Size:</strong> {orderDetails.productSize}</li>}
                  <li><strong>Shipping Address:</strong> {orderDetails.customerAddress}</li>
                  {orderDetails.customerMessage && <li><strong>Custom Message:</strong> {orderDetails.customerMessage}</li>}
                </ul>

                <h2 className="section-heading">Payment Instructions</h2>
                <p className="text-center text-muted">Please complete the payment through Zelle using the following details:</p>
                
                <div className="bg-light p-3 rounded border my-4">
                  <p className="mb-1"><strong>Zelle Account:</strong> ksgholson95@gmail.com</p>
                  <p className="mb-0"><strong>Amount Due:</strong> ${orderDetails.productPrice.toFixed(2)} + $10.00 shipping</p>
                </div>

                <p className="text-center text-muted">Your order will not be shipped until payment is recived.</p>
                
                <Button
                  variant="outline-primary"
                  as={Link}
                  to="/shop"
                  className="w-100 mt-4"
                  style={{
                    backgroundColor: '#2E073F',
                    borderColor: '#2E073F',
                    color: 'white',
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = '#EBD3F8')}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = '#2E073F')}
                >
                  Return to Shop
                </Button>
              </Card.Body>
            </Card>
          ) : (
            <p className="text-center text-danger">No order details available.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default OrderConfirmation;
